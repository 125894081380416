import styled from '@emotion/styled';
import {Box} from 'components';
import {colors, mediaQueries} from 'styles/theme';

const getWidth = (size, divisor=1) => size
    ? `${(size / divisor)}px` : '100%';

const HashtagLabel = styled.div`
  border-radius: 15px;
  margin: 0 auto;
  width: ${props => getWidth(props.size, 1.4)};
  color: ${props => props.color};
  background-color: ${props => props.bgcolor};
  transform: rotate(${props => props.rotation}deg);
  padding: 16px 14px;
  font-size: 1.3rem;

  ${mediaQueries.sm} {
    border-radius: 20px;
    padding: 20px 25px;
    font-size: 1.5rem;
    width: ${props => getWidth(props.size)};
  }
`;

HashtagLabel.defaultProps = {
  color: colors.black,
  bgcolor: colors.gray,
  rotation: 0,
}

export default HashtagLabel