import React from 'react';
import styled from '@emotion/styled';

import { RichText } from 'prismic-reactjs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { post } from 'axios';

import { LayoutSimple, SEO, Grid, Box, Text } from 'components';

import { colors, mediaQueries } from 'styles/theme';

import DeleteIcon from 'images/delete-icon.svg';

import IntroContainer from 'components/next-in-show/IntroContainer';
import HashtagLabel from 'components/next-in-show/HashtagLabel';


const CardTitle = Text.withComponent('h3');
const TextContainer = Text.withComponent('div');


// import Nav from 'components/live/Nav';
// import MediaFeed from 'components/live/MediaFeed';
// import OnlineFeed from 'components/live/OnlineFeed';

const GeneralContainer = styled(Grid)`
  a {
    text-decoration: none;
    color: ${colors.blue};

    cursor: pointer;
    &::after {
      content: '\\02192';
      display: inline-block;
      max-width: 40px;
      opacity: 1;
      transform: scale(1);
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const WelcomeContainer = styled(Grid)`
  ${Box} {
    border-bottom: 1px solid ${colors.gray};
  }
`;


const SocialBlock = styled(Grid)`
  padding: 100px 0;
  background-color: #CBD1FC;
`;

const SocialHeader = styled(Box)`
  text-transform: uppercase;
  grid-column: ${['2 / span 10']};
  text-align: center;
  padding-bottom: 35px;
`;

SocialHeader.defaultProps = {
  size: ['1.1x', '0.8x'],
};

const SocialHashtagList = styled(Box)`
  padding-bottom: 150px;
  text-align: center;
  color: #FFFFFF;

  a {
    position: relative;
    text-decoration: none;

    &:nth-of-type(1) {
      left: -80px;
      top: 10px;

      ${mediaQueries.sm} {
        left: -170px;
        top: 35px;
      }
    }

    &:nth-of-type(2) {
      left: 80px;
      top: 18px;

      ${mediaQueries.sm} {
        top: 5px;
        left: 170px;
      }
    }
  }

`;

const SocialHandleList = styled(Box)`
  text-align: center;

  ul {
    list-style-type: bullet;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  li {
    display: inline-block;
    padding-bottom: 14px;
    font-size: 1.2rem;
    &::after {
      content: '•';
      display: inline-block;
      padding: 0 10px;
    }
  }

  li:last-child {
    &::after {
      content: '';
      display: inline-block;
      padding: 0;
    }
  }
`;

const FaqHeader = styled(Box)`
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-top: 125px;
  margin-bottom: 65px;

  ${mediaQueries.md} {
    margin-top: 150px;
    margin-bottom: 100px;
  }


`

const FaqBox = styled(Box)`
  border: 1px solid ${colors.blackDimmed};
  border-radius: 35px;
  padding: 35px;
  text-align: center;

  p {
    font-size: 1.1rem;

    &.header {
      font-weight: 600;
      font-size: 1.2rem;
      padding-bottom: 2.1rem;
    }
  }
`;

// const Button = styled('div')`
//   color: ${colors.white};
//   font-weight: 600;
//   background-color: #8C8A8A;
//   border-radius: 12px;
//   border: none;
//   text-transform: uppercase;
//   padding: 20px;
//   margin-top: 25px;
//   font-size: 1rem;
//   width: ${props => (props.size +'px' || '100%')};
//   display: block;
//   cursor: pointer;
//   margin: 0 auto;
//   text-decoration: none;
// `;

// const FileButton = styled('div')`
//   input[type=file] {
//     height: 0;
//     overflow: hidden;
//     width: 0;
//   }

//   input[type=file] + label {
//     position: relative;
//     display: inline-block;
//     font-weight: normal;
//     color: ${colors.black};
//     cursor: pointer;
//     background-color: ${colors.gray};
//     border-radius: 12px;
//     padding: 20px;
//     font-size: 0.8rem;
//     margin-top: 25px;
//   }

// `;

// const FileItem = styled('div')`
//   display: inline-flex;
//   border-radius: 12px;
//   border: 1px solid ${colors.blackDimmed};
//   padding: 10px 20px;
//   font-size: 1rem;
//   width: ${props => (props.size +'px' || '100%')};
//   margin: 0 10px 20px 10px;
//   text-decoration: none;
//   flex-direction: row;
//   align-items: center;
// `;

// const FileDeleteIcon = styled('div')`
//   background-image: url('${DeleteIcon}');
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: contain;
//   background-origin: content-box;
//   cursor: pointer;

//   height: 15px;
//   width: 15px;
//   margin-right: 20px;
// `;

// const ThankYouContainer = styled(Box)`
//   border-radius: 35px;
//   background-color: ${colors.gray};
//   text-align: center;
//   padding: 100px 35px;

//   ${mediaQueries.sm} {
//     padding: 150px 35px;
//   }
// `;


class WLPS extends React.Component {
  constructor(props) {
    super(props);
    this.MAX_FILE_SIZE = 10;
    this.state = {
      files: [],
      formData: {},
      formErrors: [],
      formLoading: false,
      formSubmitted: false,
      fileUploadError: null,
      fileLoading: false,
    };

    this.onFileChange = this.onFileChange.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onInputUpdate = this.onInputUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  async onFileChange(evt) {
    const tmpFiles = [...this.state.files];
    const newFiles = [];
    const fileList = evt.target.files;
    this.setState({ fileUploadError: null });

    if ( tmpFiles.length + fileList.length > 3) {
      this.setState({ fileUploadError: 'You can only upload a maximum of 3 work references.' });
      return;
    }

    try {
      this.setState({fileLoading: true });
      Array.from(fileList).forEach(file => {
        if (file.size > (this.MAX_FILE_SIZE * 1024 * 1024)) {
          this.setState({ fileUploadError: `'${file.name}' is larger than ${this.MAX_FILE_SIZE}mb. Please choose a smaller file.` });
          return;
        }

        newFiles.push(this.fileUpload(file));
      });

      const files = await Promise.all(newFiles);
      const parsedFiles = files.map(resp => resp.data.data.files.shift());

      this.setState({ files: [ ...tmpFiles, ...parsedFiles ], fileLoading: false });
    } catch(e) {
      this.setState({ fileUploadError: 'Problem Uploading Files', fileLoading: false });
    }
  }

  fileUpload(file) {
    const url = 'https://api.b-reel.com/v1/storage';
    const formData = new FormData();
    formData.append('file', file);

    const config = { headers: { 'content-type': 'multipart/form-data' }};

    return post(url, formData, config);
  }


  onDeleteFile(index) {
    const { files } = this.state;
    this.setState({ fileUploadError: null });
    files.splice(index, 1);

    this.setState({ files })
  }

  onInputUpdate(id, val) {
    const {formData} = this.state;

    this.setState({
      formData: {
        ...formData,
        [id]: val,
      },
    });
  }

  async onSubmit() {
    const {
      first_name,
      last_name,
      email,
      instagram_handle,
      portfolio_site,
      word_1,
      word_2,
      word_3,
      university,
      major,
    } = this.state.formData;

    const formErrors = [];
    this.setState({ formErrors: [] });

    if ( ! first_name ) formErrors.push( 'first_name' );
    if ( ! last_name ) formErrors.push( 'last_name' );
    if ( ! email ) formErrors.push( 'email' );
    if ( ! university ) formErrors.push( 'university' );
    if ( ! major ) formErrors.push( 'major' );

    if (formErrors.length > 0 || this.state.files.length < 1 ) {
      this.setState({
        formErrors,
        fileUploadError: this.state.files.length < 1
          ? 'Please upload at least one work example'
          : undefined,
      });
      return;
    }

    const url = 'https://api.b-reel.com/v1/events/next-in-show/rsvps';

    const words = [];
    if ( word_1 ) words.push(word_1);
    if ( word_2 ) words.push(word_2);
    if ( word_3 ) words.push(word_3);

    try {
      this.setState({formLoading: true });
      const file_urls = this.state.files.map(({url, type}) => ({ url, type }));

      const data = {
        first_name,
        last_name,
        email,
        metadata: {
          instagram_handle: instagram_handle
            ? instagram_handle.replace('@', '')
            : undefined,
          portfolio_site,
          university,
          major,
          words,
          work: [ ...file_urls ],
        }
      }

      await post(url, data);

      this.setState({ formErrors: [], formSubmitted: true, formLoading: false });
      return;
    }catch( e ) {
      console.log('error posting data', e);
    }
  }

  render() {
    // const { data, section } = this.state;
    const { formErrors } = this.state;
    const metaData = {
      title: RichText.asText([{ text: 'B-Reel — Next In Show' }]),
      description: RichText.asText([{
        text: "Next In Show Showcase is a digital graduate showcase hosted by industry professionals to help amplify the creative voices of tomorrow"
      }]),
      image: '/img/events/share-next-in-show.jpg',
      themeColor: '#000'
    };

    return (
      <LayoutSimple>
        <SEO {...metaData} />
        <IntroContainer />

        <WelcomeContainer>
          <Box gridColumn={['2 / span 10']} textAlign="center" py={[5, 6]} px={[0, 7]}>
            <Text size={['1rem', '0.8x']} py="20px">
              Our new reality has left the class of 2020, our next generation of creative thinkers and artists, with cancelled thesis shows and an uncertain job market. B-Reel is calling on its friends and partners in the industry to donate their Instagram stories and participate in the largest digital student showcase.
            </Text>

            <Text size={['1rem', '0.8x']} py="20px">
              This is not a contest or competition.
            </Text>

            <Text size={['1rem', '0.8x']} py="20px">
              Our goal is to connect and support the class of 2020 graduates by leveraging our creative network.
            </Text>

            {/*
            <Text size={['1rem', '0.8x']} pt={[4]} pb={[3]} color={colors.red}>
              • Submissions close June 28 at 11:59PM EST •
            </Text>
            */}
          </Box>
        </WelcomeContainer>
        <Grid>
          <Box gridColumn={['2 / span 10']} textAlign="center" py={[5, 6]} px={[0, 8]}>
            <Text size={['1rem', '0.8x']} py="20px" color={colors.red}>
            • Submissions are now closed for the showcase •<br/><br/>Follow our <a href="https://www.instagram.com/explore/tags/nextinshow/" target="_blank" rel="noopener noreferrer">#NextInShow</a> / <a href="https://www.instagram.com/explore/tags/nextinshow2020/" target="_blank" rel="noopener noreferrer">#NextInShow2020</a> hashtags on Instagram to see all the great work!
            </Text>
          </Box>
        </Grid>
        {/*
        <Grid>
          { this.state.formSubmitted
            ? <ThankYouContainer gridColumn={['2 / -2', '5 / span 4' ]} my={[75, 150]}>
                <Text size={['1x', '0.8x' ]}>Thanks for your Submission!</Text>
              </ThankYouContainer>
            : <Box gridColumn={['span 12', '4 / span 6' ]} py={[75, 100]}>
                <Grid gridRowGap="40px" gridColumnGap={[ 0, 35 ]}>
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="First Name *"
                    id="first_name"
                    error={formErrors.includes('first_name')}
                    onUpdate={this.onInputUpdate} />
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="Last Name *"
                    id="last_name"
                    error={formErrors.includes('last_name')}
                    onUpdate={this.onInputUpdate}
                  />
                  <Input
                    gridColumn={['1 / -1']}
                    label="E-Mail *"
                    id="email"
                    error={formErrors.includes('email')}
                    onUpdate={this.onInputUpdate}
                  />
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="Instagram Handle"
                    id="instagram_handle"
                    onUpdate={this.onInputUpdate}
                  />
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="Portfolio Site"
                    id="portfolio_site"
                    onUpdate={this.onInputUpdate}
                  />
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="University *"
                    id="university"
                    error={formErrors.includes('university')}
                    onUpdate={this.onInputUpdate}
                  />
                  <Input
                    gridColumn={['1 / -1', 'span 6']}
                    label="Major / Discipline *"
                    id="major"
                    error={formErrors.includes('major')}
                    onUpdate={this.onInputUpdate}
                  />
                </Grid>
                <Grid py={[5]}>
                  <Box gridColumn={['1 / -1']} py={[3]}>
                    <p style={{ fontSize: '1rem', textAlign: 'center' }}>
                      3 words that describe yourself or your work
                    </p>
                  </Box>
                  <Input gridColumn={['1 / -1', 'span 4']} label="" id="word_1" onUpdate={this.onInputUpdate} />
                  <Input gridColumn={['1 / -1', 'span 4']} label="" id="word_2" onUpdate={this.onInputUpdate} />
                  <Input gridColumn={['1 / -1', 'span 4']} label="" id="word_3" onUpdate={this.onInputUpdate} />
                </Grid>
                <Grid>
                  <Box gridColumn={['1 / -1']} textAlign="center" py={[3]}>
                    <p style={{ fontSize: '1rem', lineHeight: '18pt' }}>
                      Upload up to 3 examples of your work<br /><br/>
                      <span style={{ fontWeight: 600 }}>JPG or PNG • max 10mb each • images will be resized to 1080px x 1920px</span><br />
                      <span style={{ fontWeight: 600 }}>Videos:  mp4 file • max 10mb &amp; 15 sec each • will be resized to 1080px x 1920px</span><br />
                    </p>
                    { this.state.fileUploadError
                      ? <p style={{ fontSize: '1rem', color: colors.red }}><br />{this.state.fileUploadError}</p>
                      : null
                    }

                    { this.state.fileLoading
                      ? <Loader />
                      : <FileButton>
                          <input
                            type="file" id="file" name="file"
                            onChange={this.onFileChange}
                            multiple="multiple"
                            accept="image/png, image/jpeg, image/jpg, video/mp4"
                          />
                          <label htmlFor="file">CHOOSE FILES</label>
                        </FileButton>

                    }

                  </Box>
                  <Box gridColumn={['1 / -1']} textAlign="center">
                    { this.state.files.map((file, index) =>
                        <FileItem key={`${file.filename}-${index}`}>
                          <FileDeleteIcon onClick={e => this.onDeleteFile(index)} />
                          <label>{file.filename}</label>
                        </FileItem>
                      )
                    }
                  </Box>
                  <Box gridColumn={['1 / -1']} textAlign="center" py={[3]}>
                    {this.state.formLoading
                      ? <Loader />
                      : <Button size="300" onClick={this.onSubmit}>
                          Submit &#x2199;
                        </Button>
                    }

                  </Box>
                </Grid>
            </Box>
          }
        </Grid>
        */}

        <SocialBlock>
          <SocialHeader>
            <TextContainer>Follow or Tag us on Instagram</TextContainer>
          </SocialHeader>
          <SocialHashtagList gridColumn={['3 / span 8']}>
            <a href="https://www.instagram.com/explore/tags/nextinshow/" target="_blank" rel="noopener noreferrer">
            <HashtagLabel bgcolor="#B9F606" color="#402AFF" size="300" rotation="4">#NextInShow</HashtagLabel>
            </a>
            {/* <HashtagLabel bgcolor="#B9F606" color="#402AFF" size="300" rotation="11">#WLPS2020</HashtagLabel> */}
            <a href="https://www.instagram.com/explore/tags/NextInShow2020/" target="_blank" rel="noopener noreferrer">
            <HashtagLabel bgcolor="#B9F606" color="#402AFF" size="300" rotation="-14">#NextInShow2020</HashtagLabel>
            </a>
          </SocialHashtagList>

          <SocialHeader gridColumn={['2 / -2' ]} pt={[3, 6]}>
            <TextContainer>Tune-In To the Showcase</TextContainer>
          </SocialHeader>
          <SocialHandleList gridColumn={['2 / -2', '4 / span 6']}>
            <ul>
              <li>
                @<a href="https://instagram.com/breel.co" target="_blank" rel="noopener noreferrer">breel.co</a>
              </li>
              <li>
                @<a href="https://instagram.com/twentynine_nyc" target="_blank" rel="noopener noreferrer">twentynine_nyc</a>
              </li>
              <li>
                @<a href="https://instagram.com/bbhnewyork" target="_blank" rel="noopener noreferrer">bbhnewyork</a>
              </li>
              <li>
                @<a href="https://instagram.com/goodby_silverstein" target="_blank" rel="noopener noreferrer">goodby_silverstein</a>
              </li>
              <li>
                @<a href="https://instagram.com/tbwachiatny" target="_blank" rel="noopener noreferrer">tbwachiatny</a>
              </li>
              <li>
                @<a href="https://instagram.com/preacheraustin" target="_blank" rel="noopener noreferrer">preacheraustin</a>
              </li>
              <li>
                @<a href="https://instagram.com/perfectfools" target="_blank" rel="noopener noreferrer">perfectfools</a>
              </li>
              <li>
                @<a href="https://instagram.com/useallfive" target="_blank" rel="noopener noreferrer">useallfive</a>
              </li>
              <li>
                @<a href="https://instagram.com/thewebbyawards" target="_blank" rel="noopener noreferrer">thewebbyawards</a>
              </li>
              <li>
                @<a href="https://instagram.com/interesting.development" target="_blank" rel="noopener noreferrer">interesting.development</a>
              </li>
              <li>
                @<a href="https://instagram.com/hello_superheroes" target="_blank" rel="noopener noreferrer">hello_superheroes</a>
              </li>
              <li>
                @<a href="https://www.instagram.com/superherocheesecake/" target="_blank" rel="noopener noreferrer">superherocheesecake</a>
              </li>
              <li>
                @<a href="https://instagram.com/sterling_brands" target="_blank" rel="noopener noreferrer">sterling_brands</a>
              </li>
              <li>
                @<a href="https://instagram.com/nexusstories" target="_blank" rel="noopener noreferrer">nexusstories</a>
              </li>
              <li>
                @<a href="https://instagram.com/officialhushstudios" target="_blank" rel="noopener noreferrer">officialhushstudios</a>
              </li>
              <li>
                @<a href="https://instagram.com/redandcoagency" target="_blank" rel="noopener noreferrer">redandcoagency</a>
              </li>
              <li>
                @<a href="https://instagram.com/firstborn_nyc" target="_blank" rel="noopener noreferrer">firstborn_nyc</a>
              </li>
              <li>
                @<a href="https://instagram.com/dogstudio.co" target="_blank" rel="noopener noreferrer">dogstudio.co</a>
              </li>
              <li>
                @<a href="https://instagram.com/gsdmaustin" target="_blank" rel="noopener noreferrer">gsdmaustin</a>
              </li>

            </ul>
          </SocialHandleList>
        </SocialBlock>

        <GeneralContainer>
          <Box gridColumn={['2 / -2', 'span 6']} textAlign="center" py={[4, 7]}>
            <CardTitle size={[ '1.4rem', '1.1rem' ]}>Want to spread the word?</CardTitle>
            <TextContainer size={[ '1.4rem', '1.1rem' ]}>
              <CopyToClipboard text={'https://b-reel.com/next-in-show'} onCopy={() => this.setState({ shareClicked: true })}>
                <a style={{ color: '#F73E3E'}}>Share with Friends</a>
              </CopyToClipboard>
            </TextContainer>
            {this.state.shareClicked
              ? <TextContainer size={['0.8x']} px={[4]} pt={[2]} color={colors.blackDimmed}>
                  Link copied to clipboard.<br/>Share on your social channels!
                </TextContainer>
              : null
            }

          </Box>

          <Box gridColumn={['2 / -2', 'span 6']} textAlign="center" py={[2, 7]}>
            <CardTitle size={[ '1.4rem', '1.1rem' ]}>Are you an agency?</CardTitle>
            <TextContainer size={[ '1.4rem', '1.1rem' ]}>
              <a href="mailto:claudia.brodsky@b-reel.com?subject=Next%20in%20Show:%20Get%20Involved!" style={{ color: '#5347FF'}}>Get Involved</a>
            </TextContainer>
          </Box>
        </GeneralContainer>


        <FaqHeader>
          <TextContainer size={[ '0.9x', '1.1x' ]}>Faq</TextContainer>
        </FaqHeader>

        <Grid style={{ marginBottom: '200px', gridAutoRows: '1fr', gridColumnGap: '20px'}} px={[4]} >
          <FaqBox gridColumn={['1 / -1', 'span 4']} my={[2, 0]}>
            <p className="header">If I submit my work, will I be guaranteed a post?</p>

            <p>Our goal is to include as many submissions as possible. Pending the number of participants, we may not be able to include every submission and apologize in advance.</p>
          </FaqBox>
          <FaqBox gridColumn={['1 / -1', 'span 4']} my={[2, 0]}>
            <p className="header" >How will I know if/when my work is posted?</p>

            <p>All posts will be tagged with the entrants instagram handle, if provided. You can also tune-in to the participating instagram accounts during the showcase period to look for your work.</p>
          </FaqBox>
          <FaqBox gridColumn={['1 / -1', 'span 4']} my={[2, 0]}>
            <p className="header" >What if my submission is not in accordance with submission guidelines?</p>

            <p>In order for us to properly review and post submissions, they must be meet the submission guidelines. Please read the requirements carefully before submitting. </p>
          </FaqBox>
        </Grid>

      </LayoutSimple>
    );
  }
};

export default WLPS;


