import React from 'react';
import styled from '@emotion/styled';
import { colors, mediaQueries } from 'styles/theme';

import { Box } from 'components';
import HashtagLabel from 'components/next-in-show/HashtagLabel';

import OvalBkg from 'images/oval.svg';
import OvalBkgMobile from 'images/oval-mobile.svg';

const IntroContainer = styled(Box)`
  width: 100%;
  position: relative;
  // background: #BDC5FF;
  max-height: 858px;
`;

const Intro = styled(Box)`
  width: 100%;
  padding-bottom: calc(2800 / 1800 * 100%);
  position: relative;

  ${mediaQueries.md} {
    padding-bottom: calc(858 / 1440 * 100%);
  }
  .content {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;

    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-out;

    background-image: url('${OvalBkgMobile}');
    background-repeat: no-repeat;
    background-position: center bottom ;
    background-size: 100% 100%;
    background-color: #BDC5FF;

    ${mediaQueries.md} {
      background-position: center center;
      background-image: url('${OvalBkg}');
      background-origin: content-box;

    }

    ${mediaQueries.xxl} {
      height: 818px;
    }
  }

  ${mediaQueries.xxl} {
    padding-bottom: 59.58%
  }
`;

const Header = styled('p')`
  position: relative;
  font-size: 22vw;
  line-height: 19vw;
  top: 5vw;
  color: #402AFF;

  ${mediaQueries.md} {
    font-size: 12vw;
    line-height: 10vw;
  }

  ${mediaQueries.xxl} {
    // top: -7vh;
    font-size: 10.8rem;
    line-height: 9rem;
  }
`;

const DateText = styled('p')`
  display: block;
  font-size: 6vw;
  // padding-top: 20vw;
  bottom: -30vw;
  position: relative;
  color: ${colors.blue};

  ${mediaQueries.md} {
    font-size: 3.3vw;
    padding-top: 10vw;
    bottom: 0;
  }

  ${mediaQueries.xxl} {
    padding-top: 144px;
    font-size: 3rem;
  }
`;




const HashTagContainer = styled(Box)`
  position: relative;
  left: 18vw;
  top: 4vw;

  ${mediaQueries.xxl} {
    left: 259.2px;
    top: 57.6px;
  }

  // ${mediaQueries.sm} {
  //   top: -8vh;
  //   left: 30px;
  // }

  ${HashtagLabel} {
    transform: rotate(-10deg);
    padding: 12px 14px;
    ${mediaQueries.md} {
      padding:
    }
  }
`;



const Component = (props) => {
  return (
    <IntroContainer>
      <Intro textAlign="center">
        <div className="content">
          <Header>NEXT IN<br />SHOW</Header>
          <HashTagContainer>
            <HashtagLabel bgcolor="#BBF411" color={colors.blue} size="250" rotation="-10">
              SHOWCASE
            </HashtagLabel>
          </HashTagContainer>
          <DateText>June 29 - July 1, 2020</DateText>
        </div>
      </Intro>
    </IntroContainer>
  );
};

export default Component;